@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

@mixin transition-all {
  transition: all 0.1s ease;
}

:root {
  color-scheme: dark;
}

body {
  background-color: #222;
  color: #ccc;
  font-family: "Nunito", sans-serif !important;
}

hr {
  border: 1px solid #555;
  margin-top: 25px;
  margin-bottom: 25px;
}

a {
  color: #fc0;
  @include transition-all;

  &:hover {
    color: #f80;
  }
}

b,
strong {
  font-weight: bolder;
  color: #fff;
}

input {
  background-color: unset;
  color: #ccc;
  border: 0px;
  border-bottom: 1px solid white;
  color-scheme: dark;
  @include transition-all;

  &:hover {
    border-bottom: 1px solid #fc0;
    color: #fc0;
  }

  &:focus {
    outline: none;
    border-bottom: 1px solid #f80;
    color: #f80;
  }

  &[type="text"] {
    font-family: "Nunito", sans-serif;
  }
}

button {
  font-family: "Nunito", sans-serif;
  background-color: #555;
  color: #fff;
  border: unset;
  border-radius: 5px;
  box-shadow: #5558 0px 3px;
  @include transition-all;

  &:hover {
    background-color: #fc0;
    color: #111;
    box-shadow: #fc08 0px 4px;
    transform: translateY(-1px);
  }

  &:active {
    background-color: #f80 !important;
    box-shadow: #f808 0px 0px !important;
    transform: translateY(3px);
    color: #111;
  }
}

input:not([type="text"]):hover,
button:hover {
  cursor: pointer;
}
